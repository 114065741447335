import { graphql, useStaticQuery } from "gatsby";
import React, { useCallback, useEffect, useRef } from "react";
import Modal from "../components/modules/modal";

const CartModal = ({ show, onHide, msg, msgKey, icon = "tick", cartUrl, loading }) => {
  const ref = useRef(null);

  const { labelManagerLabels } = useStaticQuery(graphql`
    {
      labelManagerLabels: labelManagerLabels {
        goToCart
        continueOnTheShop
        anErrorAsOccurred
        itemAddedToCart
        noProductsInCart
      }
    }
  `);

  useEffect(() => {
    if (show && ref.current) {
      ref.current.focus();
    }
  }, [show]);

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape") {
        onHide();
      }
    },
    [onHide]
  );

  return (
    <Modal>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-noninteractive-element-interactions */}
      <div
        ref={ref}
        className={`cart-modal${show ? " cart-modal--show" : ""}`}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={show ? 0 : -1}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        role="dialog"
        aria-hidden={!show || !cartUrl}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        onKeyDown={onKeyDown}
      >
        {loading ? (
          <div className="cart-modal__loading"></div>
        ) : (
          <div className="cart-modal__dialog" role="document">
            <div className="cart-modal__alert">
              <div className="cart-modal__content">
                {icon ? (
                  <div className={`cart-modal__icon cart-modal__icon--${icon}`}>
                    <span className={`icon-${icon}`}></span>
                  </div>
                ) : null}
                <div className="cart-modal__title">
                  {msgKey && labelManagerLabels[msgKey] ? labelManagerLabels[msgKey] : msg}
                </div>
                {cartUrl ? (
                  <div className="cart-modal__cta">
                    <a href={cartUrl} className="cart-modal__close button secondary alternate">
                      {labelManagerLabels.goToCart}
                    </a>
                  </div>
                ) : null}
                <button className="cart-modal__close button secondary" onClick={onHide}>
                  {labelManagerLabels.continueOnTheShop}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CartModal;
