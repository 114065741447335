import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

const Modal = ({ children }) => {
  const [container, setContainer] = useState(null);

  useEffect(() => {
    const container = document.createElement("div");
    setContainer(container);
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
      setContainer(null);
    };
  }, []);

  return container ? ReactDOM.createPortal(children, container) : null;
};

export default Modal;
