import { graphql, useStaticQuery } from "gatsby";

export const insertChannelsightScript = () => {
  const whereToBuyButtons = document.getElementsByClassName("cswidget");
  // Inserisci lo script solo se ci sono bottoni "cswidget"
  if (whereToBuyButtons.length) {
    // console.log('Appending Channelsight script');
    const channelsightScript = document.createElement("script");
    channelsightScript.id = `channelsight-script-injected`;
    channelsightScript.setAttribute(
      "src",
      `https://cscoreproweustor.blob.core.windows.net/widget/scripts/cswidget.loader.js`
    );
    channelsightScript.setAttribute("async", "");
    channelsightScript.setAttribute("defer", "");
    document.head.appendChild(channelsightScript);
  }
};

export const removeChannelsightScript = () => {
  // console.log('Removing Channelsight script');
  const csScript = document.getElementById(`channelsight-script-injected`);
  if (csScript) {
    csScript.remove();
  }
  const csWidgetScript = document.getElementById(`cswidgetjs`);
  if (csWidgetScript) {
    csWidgetScript.remove();
  }
};

const useWhereToBuy = (assetId) => {
  const {
    config: { isWhereToBuyActive, dataAssetId },
  } = useStaticQuery(graphql`
    {
      config: liferayGeneralConfiguration {
        isWhereToBuyActive
        dataAssetId
      }
    }
  `);

  return isWhereToBuyActive?.[0] === "yes" && !!assetId
    ? assetId
    : isWhereToBuyActive?.[0] === "yes"
    ? dataAssetId
    : null;
};

export default useWhereToBuy;
