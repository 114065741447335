import { hasShopify, queryProductsDetails as shopifyQueryProductsDetails } from "./shopify";
import {
  hasCommerceCloud,
  queryProductsDetails as ccQueryProductsDetails,
  getCartUrl as ccGetCartUrl,
  createBasket,
  getBasket,
  addProductToBasket,
  requestUserBasket,
} from "./commerce-cloud";

export const hasEcommerce = !!hasShopify || !!hasCommerceCloud;
export const getCartUrl = async (cartId) => {
  if (hasCommerceCloud) {
    return ccGetCartUrl(cartId);
  }
  return null;
};

export const createCart = async () => {
  if (hasCommerceCloud) {
    return createBasket();
  }
  return null;
};

export const queryCart = async (cartId) => {
  if (hasCommerceCloud) {
    return getBasket(cartId);
  }
  return null;
};

export const queryProductsDetails = async (productIds, currency) => {
  if (hasShopify) {
    return shopifyQueryProductsDetails(productIds);
  } else if (hasCommerceCloud) {
    return ccQueryProductsDetails(productIds, currency);
  }
  return [];
};

export const addProductToCart = async (productId, cartId) => {
  if (hasCommerceCloud) {
    return addProductToBasket(productId, cartId);
  } else {
    return null;
  }
};

export const requestUserCart = async (userData, guestCartId) => {
  if (hasCommerceCloud) {
    return requestUserBasket(userData, guestCartId);
  } else {
    return null;
  }
};
