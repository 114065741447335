import React from "react";
import classNames from "classnames";
import icons from "../../images/icons.svg";

const Icon = ({ name, color, url, width, height, className, alt }) =>
  name === "custom" ? (
    <span className={classNames("icon custom", className)}>
      <img alt={alt} src={url} style={{ width: `${width}px`, height: `${height}px` }} />
    </span>
  ) : (
    <svg className={classNames("icon", color, className)}>
      <use href={icons + "#" + name}></use>
    </svg>
  );

export default Icon;
