import React, { createContext, useCallback, useEffect, useMemo, useState, useContext } from "react";
import {
  createCart,
  queryCart,
  addProductToCart,
  getCartUrl,
  requestUserCart,
} from "../../utility/ecommerce";
import UserContext from "./user";
import CartModal from "../cart-modal";
import { productAddCart } from "../../utility/data-layer";
import useCartUrl from "../../hooks/use-cart-url";
import { getCartId, removeCartId, saveCartId } from "../../utility/cookies";
import { hasCommerceCloud } from "../../utility/commerce-cloud";

const CartContext = createContext({
  cartId: null,
  addProduct: () => null,
  cart: null,
  cartUrl: "",
  adding: false,
  loadingCart: false,
  showCartWarning: (e) => e.preventDefault(),
});

export const CartProvider = ({ children }) => {
  const { userData } = useContext(UserContext);
  const [loadingCart, setLoadingCart] = useState(true);
  const [guestCartId, setGuestCartId] = useState(null);
  const [cart, setCart] = useState(null);
  const [adding, setAdding] = useState(null);
  const [cartModalData, setCartModalData] = useState(null);
  const closeCartModal = useCallback(() => setCartModalData(null), []);

  const showCartWarning = useCallback((e) => {
    e.preventDefault();
    setCartModalData({
      msgKey: `noProductsInCart`,
      icon: false,
    });
  }, []);

  const loadUserCart = useCallback(async (cartId, userData) => {
    if (!userData) return;
    try {
      if (cartId) {
        removeCartId();
      }
      const cart = await requestUserCart(userData, cartId);
      removeCartId();
      setGuestCartId(null);
      setCart(cart);
    } catch (err) {
      //TODO gestire meglio
      console.error("Errore nel recupero del carrello utente: ", err);
      setLoadingCart(false);
      if (cartId) {
        saveCartId(cartId);
      }
    }
  }, []);

  const loadGuestCart = useCallback(async (cartId) => {
    setLoadingCart(true);
    try {
      const guestCart = await queryCart(cartId);
      setGuestCartId(guestCart.id);
      setCart(guestCart);
    } catch (err) {
      console.error("Errore nel recupero del carrello anonimo: ", err);
      console.warn("Carrello anonimo non valido, lo rimuovo");
      removeCartId();
      setGuestCartId(null);
    }
    setLoadingCart(false);
  }, []);

  useEffect(() => {
    if (userData) {
      if (!cart) {
        loadUserCart(guestCartId || getCartId(), userData);
      }
    } else if (userData === null) {
      if (!guestCartId) {
        const mgCartId = getCartId();
        if (mgCartId || hasCommerceCloud) {
          loadGuestCart(mgCartId);
        } else {
          setCart(null);
          // console.log('User not logged and no guest cart yet, do nothing');
          setLoadingCart(false);
        }
      }
    } else {
      // console.log('Waiting for user data...');
      setLoadingCart(true);
    }
  }, [userData, loadUserCart, loadGuestCart, cart, guestCartId]);

  const userCartId = userData && cart ? cart.id : null;

  const addProduct = useCallback(
    async (product, priceData, location, position) => {
      if (loadingCart) return;
      const { key_prod: productKey, sku } = product;
      let productId = productKey || sku;

      setAdding(productId);

      setCartModalData({
        loading: true,
      });

      let error = false;
      let cartId = userCartId || guestCartId;
      // Creo il carrello anonimo se non esiste ancora
      let createdCartId = null;
      if (!cartId) {
        try {
          const newCartId = await createCart();
          saveCartId(newCartId);
          cartId = newCartId;
          createdCartId = newCartId;
        } catch (err) {
          console.error(`Errore nella creazione del carrello anonimo: `, err);
          error = true;
        }
      }
      // const selected_options =
      //   product?.items?.map((item) => item.options.map((option) => option.uid))?.flat() || [];
      // Procedo con l'aggiunta del prodotto
      try {
        const data = await addProductToCart(productId, cartId, cartId === userCartId);
        setCart(data.cart);
        productAddCart(product, priceData, position);
      } catch (err) {
        console.error(`Errore nell'aggiunta al carrello di ${productId}: `, err);
        error = true;
        if (createdCartId) {
          setGuestCartId(createdCartId);
          setCart({ items: [] });
        }
      }
      setAdding(false);

      if (error) {
        setCartModalData({
          msgKey: `errorHasOccurred`,
          icon: "close",
        });
      } else {
        const cartUrl = await getCartUrl(cartId, cartId === userCartId);
        setCartModalData({
          msgKey: `itemAddedToCart`,
          cartUrl,
        });
      }
    },
    [guestCartId, userCartId, loadingCart]
  );

  useEffect(() => {
    if (cart) {
      setLoadingCart(false);
    }
  }, [cart]);

  const cartUrl = useCartUrl(cart, userCartId);

  const contextValue = useMemo(() => {
    return {
      cartId: guestCartId,
      addProduct,
      cart,
      cartUrl,
      adding,
      loadingCart,
      showCartWarning,
      cartModalData,
      closeCartModal,
    };
  }, [
    guestCartId,
    addProduct,
    cart,
    cartUrl,
    adding,
    loadingCart,
    showCartWarning,
    cartModalData,
    closeCartModal,
  ]);

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};

export const CartPageWrapper = ({ children }) => {
  const { cartModalData, closeCartModal } = useContext(CartContext);

  return (
    <>
      {children}
      <CartModal show={!!cartModalData} onHide={closeCartModal} {...cartModalData} />
    </>
  );
};

export default CartContext;
