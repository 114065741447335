import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { onetrustShowSettings } from "../utility/onetrust";
import Button from "./atoms/button";
import SiteModal from "./modal";

const GROUPS = ["C0003"];
const areCookiesEnabled = () =>
  window.OnetrustActiveGroups && GROUPS.some((item) => window.OnetrustActiveGroups.includes(item));

const VideoOverlay = ({ show, url, isYoutube, onHide, className }) => {
  const { labelManagerLabels } = useStaticQuery(graphql`
    {
      labelManagerLabels: labelManagerLabels {
        close
        youtubeNotEnabled
        customize
      }
    }
  `);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    if (show && isYoutube && window.OneTrust && !areCookiesEnabled()) {
      setShowAlert(true);
      window.OneTrust.OnConsentChanged(() => {
        setShowAlert(!areCookiesEnabled());
      });
      return () => setShowAlert(false);
    }
  }, [show, isYoutube]);

  const youtubeMatch = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url);
  // const url = !youtubeMatch && videoUrl.includes('/') ? videoUrl : '';
  const videoId = youtubeMatch && youtubeMatch[2].length === 11 ? youtubeMatch[2] : url;
  const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;

  return (
    <SiteModal show={show} onHide={onHide} className={className}>
      <div className="video">
        <div className="videoPlayer__container">
          {showAlert ? (
            <div className="videoPlayer__alert">
              <div className="videoPlayer__alert-title">{labelManagerLabels.youtubeNotEnabled}</div>
              <div className="videoPlayer__alert-buttons">
                <Button
                  data={{ label: labelManagerLabels.customize }}
                  onClick={onetrustShowSettings}
                  className="primary"
                  color="white"
                />
                <Button
                  data={{ label: labelManagerLabels.close }}
                  onClick={onHide}
                  className="primary"
                  color="white"
                />
              </div>
            </div>
          ) : (
            <iframe
              title="Hoover"
              src={videoUrl ? videoUrl : url}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            ></iframe>
          )}
        </div>
      </div>
    </SiteModal>
  );
};

export default VideoOverlay;
