import { useEffect, useState } from "react";
import { getCartUrl } from "../utility/ecommerce";

const useCartUrl = (cart, userCartId) => {
  const [cartUrl, setCartUrl] = useState(null);
  useEffect(() => {
    const loadCartUrl = async () => {
      setCartUrl(cart?.id ? await getCartUrl(cart.id, !!userCartId) : null);
    };
    loadCartUrl();
  }, [cart, userCartId]);
  return cartUrl;
};

export default useCartUrl;
